html, body, #root{
  height: 100%;
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button{
  color: #000000;
  background-color: #ff6200;
  border: solid 2px #ff851a;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
