.tweening-app{
  height: 100%;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: 100%;
  grid-template-rows: 60px auto 30px;
  background-color: #eee;
}

/* HEADER*/
.app-header {
  height: 100%;
  padding: 0 20px;
  background-color: #fff;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto 100px;
  grid-template-rows: 100%;
}
.app-header > div {
  align-self: center;
}
.app-header .session{
  justify-self: right;
}
.app-header h1{
  color: #ff6200;
  font-size: calc(10px + 2vmin);
  margin: 0;
}

/* MAIN WRAPPER */
.main-wrapper{
  width: 100%;
}
.main-wrapper .main{
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 3px 3px #bbb;
}

@media (min-width: 700px) {
  .main-wrapper {
    display: inline-grid;
    grid-template-columns: 15% 70% 15%;
  }
  .main-wrapper asside .main{
    display: inline-block;
  }
  .main-wrapper .main{
    font-size: 20vw;
  }
}
.main-wrapper aside{
  padding: 10px;
}
.main-wrapper aside.menu{
}
.main-wrapper aside, .main{
}
.main-wrapper aside.results{
}
.main{
  text-align: center;
}

/* FOOTER */
.app-footer{
  padding: 5px 20px;
  background-color: #fff;
}
.app-footer a{
  color: #000000;
}
