.STimer__label {
  user-select: none;
  font-size: 30vw;
}

@media (min-width: 700px) {
  .STimer__label{
    font-size: 20vw;
  }
}
