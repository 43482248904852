.records {
  display: inline-grid;
  grid-template-columns: 50% 50%;
}
.records .records-list{
  overflow: auto;
  max-height: 250px;
  min-width: 200px;
}
.records .records-list ul{
  padding: 0;
  margin: 0;
  list-style: none;
}
.records .records-list::-webkit-scrollbar{
   width: 3px;
}
.records .records-list::-webkit-scrollbar-thumb{
   background-color: #ff6200;
}
.records .time-label{
  font-weight: bold;
  display:inline-block;
  padding-right: 10px;
  min-width: 70px;
  text-align: right;
  color: #ff6200;
}
.records .avg{
  white-space: nowrap;
  margin: 0 auto;
}

@media (min-width: 700px) {
  .records {
    display: block;
  }
  .records .records-list{
    min-width: auto;
  }
}
